@font-face {
    font-family: "Halyard";
    src: url("Halyard-Display-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("Montserrat-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
