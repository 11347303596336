.no-underline {
	text-decoration: none;
}
.snippet-title {
	font-size: 17px;
	font-weight: 700;
	color: #D3D3D3;
}

.interactive-section-break {
	margin-top: 50px;
}

.interactive-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.prev-next-icons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 35px;
	width: 80px;
}

.prev-next-error {
	display: flex;
	align-items: center;
	font-size: 15px;
}

.right-buttons {
	display: flex;
	align-items: center;
}

.not-a-match-container {
	margin-right: 10px;
}

.review-status-dropdown-container {
	margin-left: 10px;
}

.line {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #D3D3D3;
	margin: 1em 0;
	padding: 0;
}