@import "~antd/dist/antd.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.scrollbox::-webkit-scrollbar-track {
    width: 20px;
    border-radius: 10px;
    background-color: #f0eeee;
}

div.scrollbox::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
    background-color: #c7c7c7;
}

div.scrollbox::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 10px;
    background-color: #c7c7c7;
}

@media (min-width: 1300px) {
    .ant-table-column-title { font-size: 16px; }
}

@media (min-width: 100px) and (max-width: 1300px) {
    .ant-table-column-title { font-size: 14px; }
}

.ant-table table { font-size: 16px; }
.ant-table .ant-tag { font-size: 14px; }
.ant-table .ant-btn { font-size: 14px; }

.ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
    background-color: red;
}