.section {
	margin-top: 30px;
}
.section > * {
	margin-top: 10px;
}

.notify {
	margin-bottom: 20px;
}

.title {
	font-size: 20px;
	font-weight: 400;
}

.row {
	width: 100%;
	display: flex;
}

.input {
	width: 100%;
	padding: 15px;
	border-width: 1px ;
	border-radius: 5px;
	border-style: solid;
	text-overflow: ellipsis;
	margin-right: 10px;
}

.preview-row {
	align-items: center;
	justify-content: space-between;
}

.preview-text {
	margin-right: 10px;
}
